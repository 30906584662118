import React, { useRef, useState } from "react";
import { Link } from "gatsby"

import { Swiper, SwiperSlide } from "swiper/react";

import { navigate } from 'gatsby';

import BookMasonry from './bookmasonry.js';






import "swiper/css";

import {Navigation,Autoplay } from "swiper";

export default function App(props) {

  






  return (

      <>
  <Swiper
        className=''
        spaceBetween={0}

                centeredSlides={false}
   autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}


        grabCursor={true}
  


initialSlide={1}
  onSlideChange={(swiperCore) => {
            const {
              activeIndex,
              snapIndex,
              previousIndex,
              realIndex,
            } = swiperCore;

window.scrollTo(0,0)


              if (swiperCore.realIndex === 2) {    navigate('/books/book-of-mirrors');    };

              if (swiperCore.realIndex === 0) {    navigate('/about');    };





        }}



        pagination={{
          clickable: true,
        }}
        modules={[Autoplay]}
      >



              <SwiperSlide data-swiper-autoplay="" className=' '> </SwiperSlide>





             <SwiperSlide data-swiper-autoplay="50000" className='white switchToSLC books'> 



<div className='longPage'>






<BookMasonry />





</div>




</SwiperSlide>













      <SwiperSlide data-swiper-autoplay="999999000" className=''> </SwiperSlide>

</Swiper>
       </> 
            
  );
}