import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';


import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"



export default function TitlebarBelowMasonryImageList() {
  return (
    <Box className='bookmasonry'>
      <ImageList variant="masonry" cols={2} gap={72}>



        {itemData.map((item) => (
         

               <Link className='masonryLink' to={item.link}><AniLink paintDrip hex="#001533" to={item.link}>



          <ImageListItem key={item.img}>



            <img
              srcSet={`${item.img}?w=218&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=218&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
            />
            <ImageListItemBar position="above" title={item.title} />
          </ImageListItem>
       




            </AniLink></Link>


 ))}




      </ImageList>




    </Box>
  );
}

const itemData = [
  {
   img: '/images/bookcovers/book-of-mirrors.jpg',
    title: 'Fiction | Book of Mirrors',
    link: '/books/book-of-mirrors'

  },
  {
   img: '/images/bookcovers/bad-blood.jpg',
    title: 'Fiction | Bad Blood',
        link: '/books/bad-blood'

  },
  {
   img: '/images/bookcovers/rumours.jpg',
    title: 'Non-Fiction | Rumours',
        link: '/books/rumours'

  },
  {
   img: '/images/bookcovers/gods-weapons-and-money.jpg',
    title: 'Non-Fiction | Gods, Weapons and Money',
        link: '/books/gods-weapons-and-money'

  }
];